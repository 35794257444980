<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">메뉴명</div>
            <ul class="content">
              <li class="item dateRange">
                <input-text/>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
        <ul class="lookup-button">
          <li class="add">
            <erp-button
                button-div="GET"
                @click.native="addApiClick"
            >
              API 추가
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <div class="article-left" style="width: 20%">
          <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">메뉴 리스트</div>
              <div class="header-caption">[{{menuListCount}}건]</div>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="menuList"
                  v-bind="menuListOptions"
                  @actionComplete="onActionComplete('List')"
              />
            </div>
          </div>
        </section>
        </div>
        <div class="article-right" style="width: 80%">
          <section class="article-section section-01">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title"> - 메뉴 정보 등록</div>
                <div class="header-caption">[{{menuInfoCount}}건]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="confirm keyColor">
                    <erp-button
                        button-div="SAVE"
                        :is-key-color="true"
                        :is-shortcut-button="true"
                        :ignore="isPopupOpened"
                    >
                      저장
                    </erp-button>
                  </li>
                  <li class="add">
                    <erp-button
                        button-div="SAVE"
                        :ignore="isPopupOpened"
                        :is-icon-custom="true"
                        :is-custom-shortcut-button="true"
                        shortcut-key="menuAuthApi.shortcuts.addMenuAuthApi"
                        :shortcut="{key: 'F3'}"
                        @click="addRecordClick"
                    >
                      추가
                    </erp-button>
                  </li>
                  <li class="delete">
                    <erp-button
                        button-div="DELETE"
                        :is-shortcut-button="true"
                        :ignore="isPopupOpened"
                    >
                      삭제
                    </erp-button>
                  </li>
                  <li class="refresh">
                    <erp-button
                        button-div="GET"
                        :is-icon-custom="true"
                    >
                      초기화
                    </erp-button>
                  </li>
                  <li class="print">
                    <erp-button
                        button-div="FILE"
                        @click.native="onExcelButtonClicked"
                    >
                      Excel
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="body-grid">
                <ejs-grid-wrapper
                    ref="menuInfo"
                    v-bind="menuInfoOptions"
                    :dataSource="menuInfoDataSource"
                    @headerCellInfo="onHeaderCellInfo"
                    @actionComplete="onActionComplete('Info')"
                    @queryCellInfo="onQueryCellInfo"
                />
              </div>
            </div>
          </section>
        </div>
      </article>
    </div>
    <api-select-popup
        ref="apiSelectPopup"
        v-if="isApiSelectPopup"
        @close="isApiSelectPopup = false;"
    />
    <add-api-popup
        ref="addApiPopup"
        v-if="isAddApiPopup"
        @close="isApiSelectPopup = false;"
    />
  </div>
</template>

<style scoped>
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import inputText from "@/components/common/text/InputText.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import ApiSelectPopup from "@/views/authority-management/popup/ApiSelectPopup.vue";
import AddApiPopup from "@/views/authority-management/popup/AddApiPopup.vue";
import ErpButton from "@/components/button/ErpButton";

import {Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import {getFormattedDate} from "@/utils/date";
import {numberWithCommas} from "@/utils/number";

export default {
  name: "menuAuthApi",
  components: {
    inputText,
    EjsGridWrapper,
    ApiSelectPopup,
    AddApiPopup,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      isApiSelectPopup: false,
      isAddApiPopup: false,
      menuListCount: 0,
      menuInfoCount: 0,
      menuListDataSource:[],
      menuInfoDataSource:[{}],
    };
  },
  created() {},
  computed: {
    isPopupOpened() {
      return ( this.isApiSelectPopup || this.isAddApiPopup );
    },
    menuListOptions() {
      return {
        provides: [
          Filter,
          Resize,
          Page,
          ExcelExport,
          ForeignKey,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        allowExcelExport: true,
        allowPaging: true,
        noColumnWidth: 40,
        pageSettings: { pageSize: 50 },
        columns: [
          {
            field: "menuName",
            headerText: "메뉴명",
            type: "string",
            width: 90,
          },
          {
            field: "MenuId",
            headerText: "메뉴ID",
            type: "string",
            width: 90,
          },
        ],
      };
    },
    menuInfoOptions() {
      return {
        provides: [
          Filter,
          Resize,
          Page,
          ExcelExport,
          ForeignKey,
          Edit,
        ],
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        allowExcelExport: true,
        allowPaging: true,
        noColumnWidth: 40,
        pageSettings: { pageSize: 50 },
        columns: [
          {
            field: "menuAuthDiv",
            headerText: "메뉴권한구분",
            type: "string",
            width: 90,
            isCommonCodeField: true,
            groupCode: "MENU_AUTH_DIV",
            editType: "dropdownedit",
          },
          {
            field: "ApiId",
            headerText: "API ID",
            type: "string",
            width: 90,
            allowEditing: false,
          },
          {
            field: "jobAuthDiv",
            headerText: "업무권한구분",
            type: "string",
            width: 90,
            isCommonCodeField: true,
            groupCode: "JOB_AUTH_DIV",
            editType: "dropdownedit",
          },
          {
            field: "urlPattrn",
            headerText: "URL_패턴",
            type: "string",
            width: 90,
          },
          {
            field: "ApiMethod",
            headerText: "API호출방법",
            type: "string",
            width: 90,
            isCommonCodeField: true,
            groupCode: "API_METHOD",
            editType: "dropdownedit",
          },
          {
            field: "description",
            headerText: "URL설명",
            type: "string",
            width: 90,
          },
          {
            field: "useFlag",
            headerText: '사용',
            displayAsCheckBox: true,
            textAlign: 'center',
            editType: "booleanedit",
            width: 40,
            type: 'boolean',
          },
          {
            field: "insertId",
            headerText: "등록자",
            type: "string",
            width: 90,
            allowEditing: false,
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            type: "string",
            width: 90,
            allowEditing: false,
          },
          {
            field: "updateId",
            headerText: "수정자",
            type: "string",
            width: 90,
            allowEditing: false,
          },
          {
            field: "updateDt",
            headerText: "수정일시",
            type: "string",
            width: 90,
            allowEditing: false,
          },
        ],
      };
    },
  },
  mounted() {

  },
  methods: {
    onActionComplete(div) {
      if(div === 'List') {
        this.menuListCount = numberWithCommas(
            this.$refs.menuList?.getGridBatchCount() || 0
        );
      } else if (div === 'Info') {
        this.menuInfoCount = numberWithCommas(
            this.$refs.menuInfo?.getGridBatchCount() || 0
        );
      }
    },
    addApiClick() {
      this.isAddApiPopup = true;
      this.$nextTick(()=> {
        this.$refs.addApiPopup.show();
      });
    },
    addRecordClick() {
      this.isApiSelectPopup = true;
      this.$nextTick(()=> {
        this.$refs.apiSelectPopup.show();
      });
    },
    addRecord() {
      this.$refs.menuInfo.addRecord({
        menuAuthDiv:null,
        ApiId:null,
        jobAuthDiv:null,
        urlPattrn:null,
        ApiMethod:null,
        description:null,
        useFlag:false,
        insertId:null,
        insertDt:getFormattedDate(new Date()),
        updateId:null,
        updateDt:null,
      });
      const data = this.$refs.menuInfo.getBatchCurrentViewRecords();

      this.$refs.menuInfo.editCell(data.length - 1, "caddieName");
    },
    onHeaderCellInfo(args) {
      const { cell:{ column:{ field } } } = args;
      if (
          field === "menuAuthDiv" ||
          field === "useFlag"
      ) {
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    onQueryCellInfo(args) {
      const { column: { field } } = args;
      if (
          field === "menuAuthDiv" ||
          field === "jobAuthDiv" ||
          field === "urlPattrn" ||
          field === "ApiMethod" ||
          field === "description" ||
          field === "useFlag"
      ) {
        args.cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    onViewButtonClicked() {},
    onPrintButtonClicked() {},
    onExcelButtonClicked() {
      this.$refs.menuInfo.excelExport();
    },
  },
};
</script>
