<template>
  <div id="popupWrapper">
    <ejs-dialog
        allowDragging="true"
        header="API 추가"
        isModal="true"
        ref="dialog"
        showCloseIcon="true"
        width="550"
        v-bind="dialogProps"
        @close="onDialogClose"
        @overlayClick="onDialogOverlayClick"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">API ID (자동등록)</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field required">
                              <!-- 필수 : required -->
                              <div class="title required">URL</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          ref="postUrl"
                                          v-model="post.url"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">API 호출방법</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="postApiMethods"
                                          v-model="post.apiMethods"
                                          :dataSource="apiMethodsDataSource"
                                          :allowFiltering="false"
                                          :fields="{ text: 'comName', value: 'comCode' }"
                                          cssClass="lookup-condition-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">업무구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="postJobDiv"
                                          v-model="post.jobDiv"
                                          :dataSource="jobDivDataSource"
                                          :allowFiltering="false"
                                          :fields="{ text: 'comName', value: 'comCode' }"
                                          cssClass="lookup-condition-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">설명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          v-model="post.description"
                                          maxlength="400"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="create keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-shortcut-button="true"
                  :is-key-color="true"
                  v-on:@click.native="onSubmitClick">
                저장
                </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="onCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import InputText from "@/components/common/text/InputText";
import { validateFormRefs } from "@/utils/formUtil";
import ErpButton from "@/components/button/ErpButton";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";

export default {
  name: "CreateAuthorityGroupPopup",
  components: {
    InputText,
    ErpButton, },
  props: {
    nextOrder: {
      type: Number,
    },
  },
  mounted() {
    this.show();
  },
  data() {
    return {
      post:{
        url:null,
        apiMethods: null,
        jobDiv: null,
        description: '',
      },
      validateRefList: {
        postUrl: {
          required: true,
        },
        postApiMethods: {
          required: true,
        },
        postJobDiv: {
          required: true,
        },
      },
    };
  },
  computed: {
    apiMethodsDataSource() {
      return commonCodesGetCommonCode("API_METHOD", true);
    },
    jobDivDataSource() {
      return commonCodesGetCommonCode("JOB_DIV", true);
    },
    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
      };
    },
  },
  methods: {
    validateFormRefs,
    show() {
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    onDialogOverlayClick() {
      this.hide();
    },

    onSubmitClick() {
      if (!this.validate()) {
        return;
      }

      this.$emit("submit", {
        id: this.groupId,
        name: this.groupName,
        description: this.description,
        order: this.order,
      });

      this.hide();
    },
    onCloseClick() {
      this.hide();
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },
};
</script>
<style scoped>
.body-data .data-content .field{ width: 100% !important; }
</style>