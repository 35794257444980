<template>
  <div id="popupWrapper">
    <ejs-dialog
        allowDragging="true"
        header="API 선택"
        isModal="true"
        ref="dialog"
        showCloseIcon="true"
        width="600"
        v-bind="dialogProps"
        @close="onDialogClose"
        @overlayClick="onDialogOverlayClick"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <div class="content-lookup">
                  <div class="lookup-left">
                    <ul class="lookup-condition">
                      <li class="field">
                        <div class="title">URL</div>
                        <ul class="content">
                          <li class="item dateRange">
                            <input-text/>
                          </li>
                        </ul>
                      </li>
                      <li class="field">
                        <div class="title">API 호출방법</div>
                        <ul class="content">
                          <li class="item">
                            <ejs-dropdownlist
                                v-model="apiMethodsSelect"
                                :dataSource="dropdownApiMethod"
                                :allowFiltering="false"
                                :fields="{ text: 'comName', value: 'comCode' }"
                                cssClass="lookup-condition-dropdown"
                            ></ejs-dropdownlist>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <div class="lookup-lookup">
                      <erp-button
                          button-div="GET"
                          :is-shortcut-button="true"
                      >
                        조회
                      </erp-button>
                    </div>
                  </div>
                </div>
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        v-bind="gridProps"
                        :dataSource="dataSource"
                        @queryCellInfo="onQueryCellInfo"
                      />
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="create keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
              >
                조회
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="onCloseClick"
                >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import InputText from "@/components/common/text/InputText";
import { validateFormRefs } from "@/utils/formUtil";
import ErpButton from "@/components/button/ErpButton";
import {Edit, ForeignKey, Resize} from "@syncfusion/ej2-vue-grids";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";
export default {
  name: "ApiSelectPopup",
  components: { InputText, ErpButton, ejsGridWrapper },
  mounted() {
    this.show();
  },
  data() {
    return {
      dataSource: [{}],
      apiMethodsSelect:"",
    };
  },
  computed: {
    dropdownApiMethod() {
      const field = commonCodesGetCommonCode("API_METHOD", true);
      field.push({comName:"전체", comCode: ""});
      return field;
    },
    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
      };
    },
    gridProps() {
      return {
        allowFiltering: false,
        allowSorting: false,
        isNOColumnDisplay:false,
        columns: [
            {
              field: 'useFlag',
              headerText: '선택',
              displayAsCheckBox: true,
              textAlign: 'center',
              editType: "booleanedit",
              width: 40,
              type: 'boolean',
              allowEditing: true,
            },
          {
            field: 'apiId',
            headerText: 'API_ID',
            type: "string",
            minWidth: 16,
            width: 40,
            textAlign: "center",
          },
          {
            field: 'urlPttrn',
            headerText: 'URL패턴',
            type: "string",
            minWidth: 16,
            width: 40,
            textAlign: "center",
          },
          {
            field: 'apiMethod',
            headerText: 'API호출방법',
            type: "string",
            minWidth: 16,
            width: 40,
            textAlign: "center",
          },
          {
            field: 'jobDiv',
            headerText: '업무구분',
            type: "string",
            minWidth: 16,
            width: 40,
            textAlign: "center",
          },
          {
            field: 'description',
            headerText: '설명',
            type: "string",
            minWidth: 16,
            width: 40,
            textAlign: "center",
          }
        ],
        provides: [ ForeignKey, Resize ,Edit],
      };
    }
  },
  methods: {
    validateFormRefs,
    show() {
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    onDialogOverlayClick() {
      this.hide();
    },

    onSubmitClick() {
      if (!this.validate()) {
        return;
      }

      this.$emit("submit", {
      });

      this.hide();
    },
    onCloseClick() {
      this.hide();
    },

    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
    onQueryCellInfo(args) {
      const { column:{ field } } = args;
      if (
          field === "useFlag"
      ) {
        args.cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    }
  },
};
</script>
<style>
body .lookup-condition .item.dateRange, body .lookup-condition .item.birthday { width: 130px !important; }
</style>